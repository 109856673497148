import React from 'react';
import Seo from '../components/common/Seo';
import PolicySection from '../components/Policy/section/PolicySection';
import Layout from '../layouts/Layout';

const Policy = () => {
  return (
    <Layout>
      <PolicySection />
    </Layout>
  );
};

export default Policy;

export const Head = () => {
  return <Seo />;
};
