import React from 'react';
import './style.scss';


const PolicySection = () => {
  return (
    <section id="Policy">
      <div className="container">
        <div className="row">
          <div className="Policy">
            <h3>이용약관</h3>
            <br/>
            <p>디지엠정보기술(주)에서 제공하는 포인트 및 멤버십 서비스 "CVVE Cloud"이 제공하는 서비스(이하 "서비스")는 각 제휴점 이용과 관련하여 아래와 같은 규정에 의거하여 운영 됩니다. 이용약관은 "서비스" 이용에 관련하여 당사와 회원과의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적이 있으므로 동의해야만 이용하실 수 있습니다.</p>
            <br/>
            <p class="lead">1. 용어의 정의</p>
            <br/>
            <p>
              <ul>
                <li>제휴점 : "서비스"를 이용하여 "회원"에게 포인트 및 멤버십 서비스를 제공하는 사업자</li>
                <li>회원 : "제휴점"을 방문하여 "서비스"를 이용하는 사용자</li>
                <li>멤버십 : "제휴점"에서 "회원"을 대상으로 포인트 적립 및 사용, 이벤트, 쿠폰, 주문 및 결제와 같은 회원제 서비스를 제공하는 방법</li>
                <li>포인트 : "멤버십"에서 이용금액 혹은 충전금액에 따라 "회원"에게 적립하고 사용을 할 수 있는 수단</li>
                <li>이벤트 : "제휴점"이 "회원"에게 제공하는 마케팅 및 프로모션을 통칭</li>
                <li>쿠폰 : "회원"이 "제휴점"에서 특정 상품이나 서비스로 교환할 수 있는 수단</li>
              </ul>
            </p>
            <br/>
            <p class="lead">2. "서비스" 이용 동의</p>
            <br/>
            <p>"제휴점"에서 "회원"에게 "멤버십"이용과 관련한 전반 (포인트, 쿠폰 등)에 대해 이용의사를 묻고 "회원"은 이용의사에 대한 동의 후 휴대폰 번호를 제공하는 것으로 "서비스" 이용에 동의한다.</p>
            <br/>
            <p class="lead">3. 마케팅 동의</p>
            <br/>
            <p>"서비스" 이용동의에도 불구하고 "제휴점"은 "회원"의 마케팅 수신 동의 없이 이벤트 및 쿠폰 등 마케팅 메시지를 발송 할 수 없으며, "회원"은 정보변경 기능에서 수신 여부를 변경할 수 있습니다.</p>
            <br/>
            <p class="lead">4. 실명 이용의 원칙</p>
            <br/>
            <p>"서비스" 신청 시 반드시 실명으로 신청해야 하며, 실명이 아니거나 타인의 정보를 도용하여 허위 기재했을 경우 법적 보호를 받으실 수 없습니다. 이에 따른 민·형사상의 모든 책임은 신청한 "회원"에게 있습니다. "서비스" 신청 시 본인 명의의 휴대폰 번호를 입력함으로 신청이 가능하며, 타인 명의의 휴대폰으로는 회원 가입이 불가능 합니다. "제휴점"에서 제공하는 "쿠폰"과 "포인트"는 "제휴점"의 운영방침에 따라 운영됩니다.</p>
            <br/>
            <p class="lead">5. "회원" 정보 변경</p>
            <br/>
            <p>"회원" 정보(성명, 연락처, 별명 등) 변경은 정보변경 화면에서 직접 정보변경을 통해 가능합니다. "회원" 정보변경 미 신청으로 인해 발생하는 모든 손해에 대해서는 "회원"에게 귀속됩니다.</p>
            <br/>
            <p class="lead">6. "서비스" 이용</p>
            <br/>
            <p>"서비스"의 이용은 "제휴점" 현장에서 이용하거나 "회원" 휴대폰에서 접근할 수 있는 웹 또는 앱을 통해 이용할 수 있습니다.</p>
            <br/>
            <p class="lead">7. 환불</p>
            <br/>
            <p>"포인트" 및 결제에 대한 환불은 "제휴점" 매장 내에서 "취소" 처리를 통해 이용 가능합니다.</p>
            <p>환불 방법은 CMS 자동 이체나 무통장 입금 결제의 경우 공제금액 차감 후 익월 7 일 계좌 입금이며 카드일 경우 공제금액 입금 후에 카드결제 전액 취소됩니다.</p>
            <p>* 이용 약관에 근거하여 해결되지 못하는 문제나 분쟁 발생 시에는 공정거래위원회가 고시한 소비자분쟁 해결기준에 따릅니다.</p>
            <br/>
            <p class="lead">8. 탈퇴</p>
            <br/>
            <p>"서비스" 탈퇴는 [정보변경 - 탈퇴]에서 신청가능 합니다. 탈퇴가 진행되면 "포인트" 잔액 및 적립 이력, 주문 및 결제 이력, "회원" 개인정보  등 "회원"의 모든 정보는 즉시 삭제되며 복구 되지않습니다. (단, "회원"의 약관 위반으로 인한 강제탈퇴의 경우 회원의 개인 확인을 위한 최소한의 정보만 유지 하고 모든 정보는 삭제됩니다.) 본인의 의지와 상관없이 이용 약관 위반 등으로 탈퇴 된 경우 재가입이 제한될 수 있습니다.</p>
            <br/>
            <p class="lead">9. 자격 상실</p>
            <br/>
            <p>※ 아래 사항에 해당될 경우, "서비스" 이용 자격 및 이용 신청이 영구 제한됩니다.</p>
            <p>
              <ul>
                <li>타인의 동의 없이 타인 명의의 정보를 도용한 경우</li>
                <li>"제휴점" 혹은 "회원" 등을 통해 불건전 "회원"으로 신고되고 신고사항이 사실로 밝혀진 경우</li>
                <li>타인에게 대여 혹은 양도하는 등을 통해 사고나 소비자 분쟁을 발생시키는 부정행위의 적발</li>
              </ul>
            </p>
            <br/>
            <p class="lead">10. 약관의 명시와 개정</p>
            <br/>
            <p>본 약관을 개정하는 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 그 적용일자 7일 이전부터 적용일자 전일까지 공지 합니다. 다만, "회원"에게 불리하거나 약관의 중요한 내용을 개정하는 경우에는 이용자의 합리적 판단 및 확인 가능한 시간을 고려하여 최소 30 일 이상의 유예기간을 두고 공지 합니다. 이 경우 개정 전 내용과 개정 후 내용을 비교하여 "회원"이 알기 쉽도록 표시 합니다.</p>
            <br/>
            <p>시행일자 : 2020년 6월 1일</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PolicySection;
